
/**
 * @name: 站点管理
 * @author: itmobai
 * @date: 2023-07-25 15:53
 * @description：站点管理
 * @update: 2023-07-25 15:53
 */
import { Vue, Component, Watch } from "vue-property-decorator"
import { ISite, ISiteParam } from "@/apis/site/list/types"
import { mallSiteCreateApi, mallSiteDetailApi, mallSiteModifyApi, mallSiteQueryByPageApi } from "@/apis/site/list"
import { ICrudOption } from "@/types/m-ui-crud"

const checkSiteSn = (rule: any, value: any, callback: any) => {
  if (!/^[A-Z]+$/.test(value)) {
    return callback(new Error("请输入大写的字母"))
  }
  callback()
}

const checkPhone = (rule: any, value: any, callback: any) => {
  if (value === '' || value === null || value === undefined) {
    return callback()
  }
  if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
    return callback(new Error('请输入有效的手机号'))
  }
  callback()
}

const checkRange = (rule: any, value: any, callback: any) => {
  if (!value || value == 0) {
    return callback(new Error('请输入配送范围'))
  }
  if (parseFloat(value) < 0) {
    return callback(new Error('请输入正数'))
  }
  if (!/^\d+(\.\d)?$/.test(value)) {
    return callback(new Error('请输入整数或一位小数'))
  }
  callback()
}

@Component({})
export default class StationPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: ISite[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: ISiteParam = {
    page: 1,
    limit: 10
  }
  // 地图选择器数据
  mapObj: {longitude: number, latitude: number, address: string, addressName: string} = {longitude: 0, latitude: 0, address: "", addressName: ""}
  // 表单数据
  modelForm: Partial<ISite> = {}
  // 配置信息
  crudOption: ICrudOption = {
    labelWidth: '150px',
    addTitle: "新增站点",
    editTitle: "编辑站点",
    column: [
      {
        label: "站点",
        prop: "siteKeywords",
        hide: true,
        search: true,
        addHide: true,
        editHide: true,
        placeholder: "输入名称/ID/编号模糊搜索"
      },
      {
        label: "站点负责人",
        prop: "personInChargeKeywords",
        hide: true,
        search: true,
        addHide: true,
        editHide: true,
        placeholder: "输入姓名/手机号模糊搜索"
      },
      {
        label: "ID",
        prop: "id",
        align: "left",
        width: 180,
        editHide: true,
        addHide: true
      },
      {
        label: "站点名称",
        prop: "siteName",
        align: "center",
        overHidden: true,
        maxlength: 20,
        rules: [
          {required: true, message: '请输入站点名称' , trigger: 'blur'}
        ]
      },
      {
        label: "站点编号",
        prop: "siteSn",
        align: "center",
        width: 100,
        maxlength: 4,
        rules: [
          {required: true, message: '请输入站点编号' , trigger: 'blur'},
          {validator: checkSiteSn, trigger: "blur"}
        ]
      },
      {
        label: "站点负责人",
        prop: "sitePersonInCharge",
        align: "center",
        overHidden: true
      },
      {
        label: "联系方式",
        prop: "contactPhone",
        align: "center",
        width: 150,
        maxlength: 11,
        rules: [
          {required: false, validator: checkPhone, trigger: 'blur'}
        ]
      },
      {
        label: "站点定位",
        prop: "siteAddressName",
        align: "center",
        overHidden: true,
        search: true,
        editSlot: true,
        addSlot: true,
        rules: [
          {required: true, message: '请选择站点' , trigger: 'blur'},
        ]
      },
      {
        label: "配送范围(区域半径)",
        prop: "deliveryRange",
        align: "center",
        width: 150,
        addSlot: true,
        editSlot: true,
        rules: [
          {required: true, message: "请输入配送范围", trigger: 'blur'},
          {validator: checkRange, trigger: 'blur'}
        ]
      },
      {
        label: "状态",
        prop: "status",
        align: "center",
        width: 150,
        addHide: true,
        editHide: true,
        slot: true
      }
    ]
  }

  @Watch('mapObj', {immediate: true, deep: true})
  onMapObjChange (newVal: {longitude: number, latitude: number, address: string, addressName: string}) {
    this.modelForm.siteAddress = newVal.address
    this.modelForm.siteAddressName = newVal.addressName
    this.modelForm.siteLatitude = String(newVal.latitude)
    this.modelForm.siteLongitude = String(newVal.longitude)
  }

  openAdd () {
    this.mapObj = {latitude: 0, longitude: 0, address: '', addressName: ''}
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }

  openEdit (row: ISite, index: number) {
    mallSiteDetailApi(row.id).then(e => {
      if (e) {
        this.mapObj = {
          latitude: parseFloat(e.siteLatitude),
          longitude: parseFloat(e.siteLongitude),
          address: e.siteAddress,
          addressName: e.siteAddressName
        }
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
        // @ts-ignore
        this.$refs.crudRef.updateFormColumn("siteSn", {
          disabled: true
        })
      }
    })
  }

  getList () {
    this.tableLoading = true
    mallSiteQueryByPageApi(this.queryForm).then(e => {
      this.tableTotal = e.total
      this.tableData = e.list
      this.tableLoading = false
    })
  }

  statusChange (id: string, status: number) {
    mallSiteModifyApi({id, status} as ISite).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  rowSave (form: ISite, done: Function, loading: Function) {
    mallSiteCreateApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: ISite, done: Function, loading: Function) {
    mallSiteModifyApi(form).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  created () {
    this.getList()
  }
}
